import React, { useState } from "react";
import { ComponentStory, ComponentMeta } from "@storybook/react";
import Input from "./Input/Input.component";
import PasswordInput from "./PasswordInput/PasswordInput.component";
import DateInput from "./DateInput/DateInput.component";
import MoneyInput from "./MoneyInput/MoneyInput.component";

export default {
  title: "Wills/Components/Input",
  component: Input,
  argTypes: {
    type: {
      control: {
        type: "select",
        options: ["text", "number", "password", "email", "date"],
      },
    },
  },
} as ComponentMeta<typeof Input>;

const Template: ComponentStory<typeof Input> = (args) => {
  const [value, onChange] = useState("");
  return (
    <Input
      {...args}
      value={value}
      onChange={(e) => onChange((e.target as HTMLInputElement).value)}
    />
  );
};

const PasswordTemplate: ComponentStory<typeof PasswordInput> = (args) => {
  const [value, onChange] = useState("");
  return (
    <PasswordInput
      {...args}
      onChange={(e) => onChange((e.target as HTMLInputElement).value)}
      value={value}
    />
  );
};

const DateInputTemplate: ComponentStory<typeof DateInput> = (args) => {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [error, setError] = useState("");

  const customValidation = () => {
    if (day && month && year) {
      const getAge = () =>
        Math.floor(
          (new Date().getTime() -
            new Date(
              parseInt(year),
              parseInt(month) - 1,
              parseInt(day),
              1,
              0
            ).getTime()) /
            3.15576e10
        );
      const age = getAge();
      if (age < 18) {
        setError("Sorry you must be over the age of 18.");
      } else {
        setError("");
      }
    }
  };

  return (
    <DateInput
      {...args}
      day={day}
      month={month}
      year={year}
      setDay={setDay}
      setMonth={setMonth}
      setYear={setYear}
      errorMessage={error}
      validation={customValidation}
    />
  );
};

export const TextInput = Template.bind({});
export const Password = PasswordTemplate.bind({});
export const BirthDateInput = DateInputTemplate.bind({});

const MoneyTemplate: ComponentStory<typeof MoneyInput> = (args) => (
  <MoneyInput {...args} />
);

export const Money = MoneyTemplate.bind({});

TextInput.args = {
  placeholder: "Placeholder",
  value: "",
  disabled: false,
  isLong: false,
  errorMessage: "",
  heading: "Name",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
};

Password.args = {
  disabled: false,
  errorMessage: "",
  heading: "Password (minimum 10 characters)",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
};

BirthDateInput.args = {
  heading: "Date Of Birth",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
};

Money.args = {
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  heading: "Money",
  errorMessage: "",
  onChange: (value) => console.log(value),
};
