import {
  AllAdditionalServices,
  WillProductName,
} from "@ifgengineering/hip-app-domain/src";
import React from "react";
import { CURRENCY } from "../../../constants/currency";
import { CartType } from "./types";

export enum FreeServicesNames {
  PORTFOLIO_HEALTHCHECK = "portfolio_healthcheck",
}

export enum WillProductNames {
  STANDARD = "standard",
  STANDARD_TRUST_BASED = "standard_trust_based",
}

export const willLabels: Record<WillProductName, string> = {
  standard: "Standard Will",
  standard_trust_based: "Trust Based Will",
};

export const willPrices: Record<WillProductName, number> = {
  standard: 98,
  standard_trust_based: 348,
};

export const initialCartState: CartType = {
  will: "standard",
  additionalServiceIDs: [],
  items: [],
  total: 98,
};

export type AdditionalServicesType = {
  id: AllAdditionalServices | WillProductName; //ID aligned with server and stripe name
  title: string;
  price: number;
  priceLabel: string;
  cardState: "ADD" | "CLAIM" | "ADDED";
  moreInfo: React.ReactElement;
  list: boolean;
};

/**
 * This is mock data which would probably be fetched from an API in the future.
 */
const additionalServices: Record<string, AdditionalServicesType> = {
  standard_trust_based: {
    id: "standard_trust_based",
    title: "Trust-Based Will upgrade",
    price: 348,
    priceLabel: `${CURRENCY.POUND}348`,
    cardState: "CLAIM",
    list: true,
    moreInfo: (
      <>
        Upgrade to a Trust Based Will and allow your family to save up to 40% in
        inheritence tax when inheriting your estate! We highly recommend you do
        this if your assets are above £325,000 or any of your beneficiaries are
        currently receiving benefits. Find out more here:{" "}
        <a href="https://youtu.be/uSP-V5Hz_4g" target="_blank" rel="noreferrer">
          https://youtu.be/uSP-V5Hz_4g
        </a>
      </>
    ),
  },
  unlimited_edits: {
    id: "unlimited_edits",
    title: "Unlimited Amendment Service",
    price: 10,
    priceLabel: `${CURRENCY.POUND}10 Annually`,
    cardState: "ADD",
    list: true,
    moreInfo: (
      <>
        Make it convenient to keep your Will up to date with the changes in your
        circumstances (change of address, family members, or assets). One-off
        amendments are otherwise £30.
      </>
    ),
  },
};

export { additionalServices };
