import axios from "axios";
import {
  WillPaymentRequest,
  WillPaymentResponse,
} from "@ifgengineering/hip-app-domain/src";
import { loadStripe } from "@stripe/stripe-js";
import { CORE_API } from "gatsby-env-variables";
import { navigate } from "gatsby";

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY || "");

export const WillPaymentCheckout = async (
  data: WillPaymentRequest
): Promise<void> => {
  const res = await axios.post<WillPaymentResponse>(
    `${CORE_API}/will/payment`,
    {
      ...data,
    },
    {
      withCredentials: true,
    }
  );

  if (res.data.paymentRequired === false) {
    navigate("/payment/confirmation");
  }

  const stripe = await stripePromise;

  if (stripe && res.data.stripeCheckoutSessionId) {
    await stripe.redirectToCheckout({
      sessionId: res.data.stripeCheckoutSessionId,
    });
    return;
  }

  console.error("Error initiating Stripe");
};
