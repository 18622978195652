import React from "react";
import styled from "styled-components";
import Button from "../../../../legacyComponents/Button/Button.component";
import { theme } from "../../../../styles/legacy-theme";
import { UpgradeCardProps } from "./UpgradeCard.component";
import { ButtonConfiguration, UpgradeCardStates } from "./const";
import RubbishCanIcon from "../../../../assets/icons/rubbish_can_icon.svg";

const Layout = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  flex-grow: 1;
`;

const Price = styled.div`
  color: ${theme.colors.Font02};
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
`;

const Title = styled.div`
  color: ${theme.colors.Font02};
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
  padding: 10px 0;
`;

const MoreInfo = styled.div`
  color: ${theme.colors.Primary01};
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5rem;
  text-align: left;
  text-decoration: underline;
  user-select: none;
  display: flex;
  flex-grow: 1;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

const ActionItems = styled.div`
  display: flex;

  button {
    width: 120px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div``;

type DesktopVersionProps = UpgradeCardProps & {
  toggleMoreInfo: () => void;
  showMoreInfo: boolean;
};

const DesktopVersion = ({
  cardState = UpgradeCardStates.ADD,
  onClick,
  onRemove,
  price,
  showDeleteButton,
  showMoreInfo,
  title,
  toggleMoreInfo,
  customButtonText,
}: DesktopVersionProps): JSX.Element => {
  const buttonText = customButtonText || ButtonConfiguration[cardState].label;
  const buttonVersion = ButtonConfiguration[cardState].buttonVersion;
  const moreInfoText = showMoreInfo ? "Less info" : "More info";

  return (
    <Layout>
      <TextWrapper>
        <Price>{price}</Price>
        <TitleWrapper>
          <Title>{title}</Title>
          {showDeleteButton && (
            <RubbishCanIcon onClick={onRemove} data-testid="remove-button" />
          )}
        </TitleWrapper>
      </TextWrapper>
      <ButtonWrapper>
        <ActionItems>
          <Button text={buttonText} version={buttonVersion} onClick={onClick} />
          <MoreInfo onClick={toggleMoreInfo}>{moreInfoText}</MoreInfo>
        </ActionItems>
      </ButtonWrapper>
    </Layout>
  );
};

export default DesktopVersion;
