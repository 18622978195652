import styled from "styled-components";
import { theme } from "../../../../styles/legacy-theme";

type Props = {
  hasError?: boolean;
  isValid?: boolean;
};

export const Bar = styled.div`
  color: ${theme.colors.Font02};
  width: 100%;
  height: 0.25rem;
  border-radius: 8px;
  background-color: #eaeaea;
  margin: 0.5rem 0;
`;

export const InnerBar = styled.div<Props>`
  color: ${theme.colors.Font02};
  height: 0.25rem;
  width: ${({ hasError, isValid }) =>
    hasError || isValid ? "100%" : "0.25rem"};
  border-radius: 8px;
  background-color: ${({ hasError }) =>
    hasError ? theme.colors.ErrorRed : theme.colors.Primary01};
  margin: 0.5rem 0;
`;
