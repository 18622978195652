import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../../components/mediaQueries";
import { theme } from "../../../../styles/legacy-theme";
import { UpgradeCardStates } from "./const";
import CardContainer from "../../../../legacyComponents/CardContainer/CardContainer.component";
import DesktopVersion from "./DesktopVersion.component";
import MobileVersion from "./MobileVersion.component";

const MoreInfo = styled.div`
  background: ${theme.colors.LightBlue};
  border-radius: 0 0 8px 8px;
`;

const MoreInfoContent = styled.div`
  padding: 15px;
`;

export type UpgradeCardProps = {
  id?: string;
  moreInfo?: React.ReactNode;
  customButtonText?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onRemove: (e: React.MouseEvent<HTMLButtonElement>) => void;
  price: string;
  title?: string;
  cardState?: UpgradeCardStates;
  showDeleteButton?: boolean;
};

const UpgradeCard = ({ moreInfo, ...props }: UpgradeCardProps): JSX.Element => {
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });

  return (
    <CardContainer padding={0}>
      {isMobile ? (
        <MobileVersion
          {...props}
          toggleMoreInfo={() => setShowMoreInfo(!showMoreInfo)}
          showMoreInfo={showMoreInfo}
        />
      ) : (
        <DesktopVersion
          {...props}
          toggleMoreInfo={() => setShowMoreInfo(!showMoreInfo)}
          showMoreInfo={showMoreInfo}
        />
      )}
      {showMoreInfo && moreInfo && (
        <MoreInfo>
          <MoreInfoContent>{moreInfo}</MoreInfoContent>
        </MoreInfo>
      )}
    </CardContainer>
  );
};

export default UpgradeCard;
