import { ButtonStates } from "../../../../legacyComponents/Button/Button.component";

export enum UpgradeCardStates {
  ADDED = "ADDED",
  ADD = "ADD",
  CLAIM = "CLAIM",
}

interface Configuration {
  label: string;
  buttonVersion: ButtonStates;
}

export const ButtonConfiguration: { [key: string]: Configuration } = {
  [UpgradeCardStates.ADD]: {
    label: "Add",
    buttonVersion: ButtonStates.PRIMARY_GHOST_LARGE,
  },
  [UpgradeCardStates.ADDED]: {
    label: "Added",
    buttonVersion: ButtonStates.PRIMARY_GHOST,
  },
  [UpgradeCardStates.CLAIM]: {
    label: "Claim",
    buttonVersion: ButtonStates.PRIMARY_GHOST_LARGE,
  },
};
