import React, { useState } from "react";
import Container from "../styled/Container";
import Text from "../styled/Text";
import Heading from "../styled/Heading";
import TextInput from "../styled/TextInput";
import InputIconContainer from "../styled/InputIconContainer";
import IconWrapper from "../styled/IconWrapper";
import { Bar, InnerBar } from "../styled/PasswordBar";
import Eye from "../../../../assets/icons/eye.svg";
import ErrorContainer from "../styled/ErrorContainer";

type Props = {
  value: string;
  testId?: string;
  name?: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errorMessage?: string;
  description?: string;
  heading?: string;
  disabled?: boolean;
  hideBar?: boolean;
};

const Password: React.FC<Props> = ({
  value,
  testId,
  onChange,
  disabled,
  heading,
  errorMessage,
  description,
  name,
  hideBar,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [validation, setValidation] = useState<string | null>("");
  const error = validation || errorMessage;
  const isPasswordValid = value.length > 7;

  // This action will not be required when we use Formik...
  // Formik will handle all the validation through Yup and simply
  // give us access to an error message which we will pass down as a prop to this component
  const blurAction = () => errorMessage && setValidation(errorMessage);

  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      {description && <Text>{description}</Text>}
      <InputIconContainer>
        <TextInput
          data-testid={testId}
          withIcon={true}
          onBlur={blurAction}
          type={showPassword ? "text" : "password"}
          placeholder="**********"
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => onChange(e)}
          value={value}
          disabled={disabled}
          hasError={!!errorMessage}
          name={name}
        />
        <IconWrapper>
          <Eye
            alt="show-password"
            data-testid="show-password"
            onClick={() => setShowPassword((prevState) => !prevState)}
          />
        </IconWrapper>
      </InputIconContainer>
      {!hideBar && (
        <Bar>
          <InnerBar hasError={!!error} isValid={isPasswordValid} />
        </Bar>
      )}
      {error && (
        <ErrorContainer>
          <Text hasError={!!error}>{error}</Text>
        </ErrorContainer>
      )}
    </Container>
  );
};

export default Password;
