import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentLayout from "../../layouts/PaymentLayout";
import UpgradeCard from "@willsComponents/Cards/UpgradeCard/UpgradeCard.component";
import { UpgradeCardStates } from "@willsComponents/Cards/UpgradeCard";
import Button, {
  ButtonStates,
} from "@legacyComponents/Button/Button.component";
import { WillPaymentCheckout } from "../../lib/payments/checkout";
import {
  additionalServices,
  AdditionalServicesType,
  willLabels,
  willPrices,
} from "../../lib/payments/constants";
import {
  AllAdditionalServices,
  PaidServiceNames,
  WillProductName,
} from "@ifgengineering/hip-app-domain/src";
import { CURRENCY } from "../../../constants/currency";
import { getUserEmail } from "@components/Login/selectors";
import {
  getCharity,
  getCharityProductList,
  getDonation,
} from "@components/Charity/selectors";
import { Money } from "@legacyComponents/Form/input/Input.stories";
import {
  createPartnerCharityDonation,
  updateCharityDonation,
} from "@components/Charity/actions";
import {
  AdditionalServicesContainer,
  AdditionalServicesListContainer,
  DonationCotainer,
  DonationFormContainer,
  LineBreak,
  OriginalPrice,
  SubheadingText,
  UnderText,
  YourPurchaseContainer,
  YourPurchaseDetail,
  YourPurchaseDetailContainer,
  YourPurchasePrice,
  YourPurchaseTitle,
} from "./styled";

const PurchaseSummary: React.FC = () => {
  const productList = useSelector(getCharityProductList);
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);
  const charity = useSelector(getCharity);
  const donation = useSelector(getDonation);

  const [services, setServices] = useState(additionalServices);
  const [donationVal, setDontionVal] = useState<number>(donation?.amount || 0);
  const [total, setTotal] = useState<number>(0);
  const [willType, setWillType] = useState<WillProductName>("standard");
  const [aditionalServices, setAditionalServices] = useState<
    AdditionalServicesType[]
  >([]);
  const charityStandardWill =
    productList &&
    productList?.find((prod) => prod.productType === willLabels.standard);
  const charityTrustWill =
    productList &&
    productList?.find(
      (prod) => prod.productType === willLabels.standard_trust_based
    );
  const additionalServiceIDs: PaidServiceNames[] = aditionalServices.map(
    ({ id }) => id as PaidServiceNames
  );
  const standardWillPrice =
    charityStandardWill?.amount !== undefined
      ? charityStandardWill?.amount
      : willPrices.standard;
  const trustBasedWillPrice =
    charityTrustWill !== undefined
      ? charityTrustWill?.amount
      : willPrices.standard_trust_based;
  const willPrice =
    willType === "standard" ? standardWillPrice : trustBasedWillPrice;

  useEffect(() => {
    if (charityTrustWill) {
      setServices({
        ...services,
        standard_trust_based: {
          ...additionalServices.standard_trust_based,
          price: charityTrustWill.amount,
          priceLabel: `${CURRENCY.POUND}${charityTrustWill.amount}`,
        },
      });
    }
  }, [productList]);

  useEffect(() => {
    if (donation?.amount) {
      setDontionVal(donation?.amount);
    }
  }, [donation?.amount]);

  useEffect(() => {
    const servicesPrice = aditionalServices.reduce(
      (acc, val) => acc + val.price,
      0
    );

    const donationValue = donation?.amount || 0;

    setTotal(willPrice + donationValue + servicesPrice);
  }, [donation?.amount, willType, willPrice, aditionalServices.length]);

  const handleAddServiceClick = (
    serviceId: AllAdditionalServices | WillProductName
  ) => {
    if (serviceId === "standard" || serviceId === "standard_trust_based") {
      setWillType(serviceId);
    } else {
      setAditionalServices([...aditionalServices, services[serviceId]]);
    }

    setServices({
      ...services,
      [serviceId]: {
        ...services[serviceId],
        cardState: "ADDED",
      },
    });
  };

  const handleRemoveServiceClick = (
    serviceId: AllAdditionalServices | WillProductName
  ) => {
    const itIsAWillTypeChange =
      serviceId === "standard" || serviceId === "standard_trust_based";
    const cardState = itIsAWillTypeChange ? "CLAIM" : "ADD";

    if (itIsAWillTypeChange) {
      setWillType(
        serviceId === "standard" ? "standard_trust_based" : "standard"
      );
    } else {
      setAditionalServices(
        aditionalServices.filter(({ id }) => id !== serviceId)
      );
    }

    setServices({
      ...services,
      [serviceId]: {
        ...services[serviceId],
        cardState,
      },
    });
  };

  const priceBreakDown = () => {
    const standardTitle = charityStandardWill
      ? "Your will"
      : willLabels.standard;
    const willTitle = services[willType]?.title || standardTitle;
    const originalPriceLabel =
      willType === "standard"
        ? `£${willPrices.standard}`
        : `£${willPrices.standard_trust_based}`;

    const servicesItems = Object.keys(services).map((key) => {
      const { id, title, cardState, price } = services[key];

      if (
        cardState === "ADDED" &&
        id !== "standard" &&
        id !== "standard_trust_based"
      ) {
        const priceLabel = !price ? "FREE" : `£${price}`;
        const originalPriceLabel = !willPrices[id as WillProductName]
          ? ""
          : `£${willPrices[id as WillProductName]}`;

        return (
          <YourPurchaseDetail key={id}>
            <YourPurchaseTitle>{title}</YourPurchaseTitle>
            {originalPriceLabel && (
              <OriginalPrice>{originalPriceLabel}</OriginalPrice>
            )}
            <YourPurchasePrice>{priceLabel}</YourPurchasePrice>
          </YourPurchaseDetail>
        );
      }

      return null;
    });

    return (
      <>
        <YourPurchaseDetail key="will">
          <YourPurchaseTitle>{willTitle}</YourPurchaseTitle>
          {charity.id && <OriginalPrice>{originalPriceLabel}</OriginalPrice>}
          <YourPurchasePrice>
            {willPrice ? `£${willPrice}` : "FREE"}
          </YourPurchasePrice>
        </YourPurchaseDetail>
        {servicesItems}
        {donation?.amount !== undefined && (
          <YourPurchaseDetail key="donation">
            <YourPurchaseTitle>{`Your Donation To ${charity.displayName}`}</YourPurchaseTitle>
            <YourPurchasePrice>£{donation?.amount}</YourPurchasePrice>
          </YourPurchaseDetail>
        )}
      </>
    );
  };

  return (
    <PaymentLayout title="Your purchase" total={total}>
      <YourPurchaseContainer>
        <SubheadingText>Your Purchase</SubheadingText>
        <LineBreak />
        <YourPurchaseDetailContainer>
          {priceBreakDown()}
        </YourPurchaseDetailContainer>
      </YourPurchaseContainer>
      {charity.id && (
        <DonationCotainer>
          <SubheadingText>
            Your Donation To {charity.displayName}
          </SubheadingText>
          <UnderText>
            {charity.displayName} is kindly sponsoring the cost of this Will as
            part of their wider work to support the Muslim community and donors.
            A donation of £100 will really help them support this project.
          </UnderText>
          <LineBreak />
          <DonationFormContainer>
            <h3>Donation Amount</h3>
            <div>
              <Money
                value={donationVal?.toString() || "0"}
                max="998999"
                onChange={(amount) => {
                  setDontionVal(Number.parseInt(amount || "0"));
                }}
              />
              {/* The donation can be zero */}
              {donation?.amount !== undefined ? (
                <Button
                  text="Change"
                  version={ButtonStates.PRIMARY_GHOST_LARGE}
                  onClick={() => {
                    dispatch(
                      updateCharityDonation({
                        email: userEmail,
                        data: {
                          ...donation,
                          amount: donationVal,
                        },
                      })
                    );
                  }}
                />
              ) : (
                <Button
                  text="Add"
                  version={ButtonStates.PRIMARY_GHOST_LARGE}
                  onClick={() => {
                    dispatch(
                      createPartnerCharityDonation({
                        email: userEmail,
                        data: {
                          ...donation,
                          amount: donationVal,
                          giftAid: false,
                          title: "",
                        },
                      })
                    );
                  }}
                />
              )}
            </div>
          </DonationFormContainer>
        </DonationCotainer>
      )}
      <AdditionalServicesContainer>
        <SubheadingText>Additional Services and Upgrades</SubheadingText>
        <LineBreak />
        <AdditionalServicesListContainer>
          {Object.keys(services).map((key, i) => {
            const { id, title, priceLabel, moreInfo, cardState, list } =
              services[key];

            if (list) {
              const customButtonText =
                id === "standard_trust_based" &&
                cardState !== UpgradeCardStates.ADDED
                  ? "Upgrade"
                  : "";

              return (
                <UpgradeCard
                  key={i}
                  title={title}
                  price={priceLabel}
                  customButtonText={customButtonText}
                  cardState={UpgradeCardStates[cardState]}
                  moreInfo={moreInfo}
                  onClick={() => {
                    if (cardState !== UpgradeCardStates.ADDED) {
                      handleAddServiceClick(id);
                    }
                  }}
                  onRemove={() => handleRemoveServiceClick(id)}
                  showDeleteButton={cardState === UpgradeCardStates.ADDED}
                />
              );
            }

            return null;
          })}
        </AdditionalServicesListContainer>
      </AdditionalServicesContainer>
      <div>
        <Button
          text="Continue"
          onClick={() => {
            WillPaymentCheckout({
              email: userEmail,
              cancelUrl: `${location.origin}/app/wills`,
              successUrl: `${location.origin}/payment/confirmation`,
              willProductName: willType,
              additionalServices: additionalServiceIDs,
              // Use the API value instead of the state value because some people forget to click on the Change button
              // they see the new value on Stripe, but a different one on the Summary page
              donation: donation?.amount || 0,
            });
          }}
        />
      </div>
    </PaymentLayout>
  );
};

export default PurchaseSummary;
